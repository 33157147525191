import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import axiosInstance from "../../utils/axiosConfig";
import Footer from "../Footer";
import "../../styles/getStartedPage.css";
import Google from "../../assets/icons/white-google.webp";
import grayGoogle from "../../assets/icons/gray-google.webp";
import { Snackbar, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import getFirebaseIdToken from '../../Firebase/getFirebaseIdToken';
import "../../styles/DoroChat.css";
import { auth } from "../../firebase";
import "firebase/compat/auth";
import MetaTags from 'react-meta-tags';
import ForgotPassPopup from './ForgotpassPopup';

const provider = new GoogleAuthProvider();

function StartSection() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [showResetpassPopup, setShowResetpassPopup] = useState(false);

  useEffect(() => {
    const tokenCheck = async () => {
      const idToken = await getFirebaseIdToken(); 
      if (idToken) {
        console.error("User is already logged-in.");
        navigate('/');
        return;
      }
    };
    tokenCheck();
  }, [navigate]);

  useEffect(() => {
    const updateScreenState = () => {
      const screenWidth = window.innerWidth;
      let newState = "";
      if (screenWidth >= 360 && screenWidth <= 768) {
        newState = true;
      } else {
        newState = false;
      }
      setIsMobile(newState);
    };
    updateScreenState();
    window.addEventListener("resize", updateScreenState);
    return () => {
      window.removeEventListener("resize", updateScreenState);
    };
  }, []);

  const handleClose = () => {
    setSnackBar(false);
  };

  const message = (
    <div style={{ padding: "5px 10px" }}>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  const handleRegisterClick = async () => {
    if (!email || !password) {
      setSnackBar(true);
      return;
    }
    setLoading(true);
    try {
      // Try to create a new account
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (!userCredential) {
        navigate("/doro-test-start");
        return;
      }

      const user = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
      };
      const idToken = await userCredential.user.getIdToken();

      await axiosInstance
        .post(`/users/me/`, user, {
          headers: {
            Authorization: `${idToken}`,
          },
        })
        .then((response) => {
          localStorage.setItem(
            "userInformation",
            JSON.stringify(response.data)
          );
          navigate("/doro-test-start");
        }).catch(async (error) => {
          if (error?.response?.status === 409) {
            // User already exists, navigate to the profile page
            // We shouldn't technically get her as firebase should already know the email is in use, but just in case.
            navigate("/profile");
          } else {
            console.error("Error processing sign-in:", error);
          }
        });
    } catch (error) {
      if (error.code !== "auth/email-already-in-use") {
        alert("Error creating account: " + error.message);
        return;
      }
      // If the account already exists, try to sign in
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/profile");
      } catch (signInError) {
        // Handle sign-in errors here
        alert("Error signing in: " + signInError.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);

    try {
      // Attempt to sign in with Firebase
      const result = await signInWithPopup(auth, provider);
      const userCredential = result;
      const userData = userCredential.user;
      const user = {
        uid: userData.uid,
        email: userData.email,
      };

      // Send the ID token to your backend to check if the user is new or existing
      axiosInstance
        .post(`/users/me/`, user, {
          headers: {
            Authorization: `${userData.accessToken}`,
          },
        })
        .then((response) => {
          localStorage.setItem(
            "userInformation",
            JSON.stringify(response.data)
          );
          navigate("/doro-test-start");
        })
        .catch(async (error) => {
          if (error?.response?.status === 409) {
            navigate("/profile");
          } else {
            // Handle other errors here
            console.error("Error processing sign-in:", error);
          }
        })
    } catch (error) {
      console.error("Firebase sign-in error:", error);
    } finally {
      setLoading(false);
    }
  };

  return !isMobile ? (
    <div className="start-wrapper fade-in">
      <MetaTags>
        <title>Sign In or Sign Up - Doro: Mental Health Progress & Support</title>
        <meta name="description" content="Sign in or sign up with Doro by Razroze to access mental health questionnaires, track your progress, and engage in early self-awareness for better well-being." />
        <link rel="canonical" href="https://doro.razroze.ca/get-started" />
      </MetaTags>
      <div className="start-section">
        <div className="section">
          <div className="right-container">
            <div className="input-section">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password" style={{ marginTop: "10px" }}>
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {loading ? (
                <div
                  className="progress-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress
                    sx={{ color: "#FFFFFF" }}
                    className="progress"
                  />
                </div>
              ) : (
                <>

                  <span className="signin forgot-password" onClick={() => setShowResetpassPopup(true)} style={{ cursor: 'pointer' }}>Forgot password?</span>
                  {showResetpassPopup && (
                    <ForgotPassPopup
                        onClose={() => setShowResetpassPopup(false)}
                    />
                  )}

                  <div className="button-container">
                    <button
                      className="register-button"
                      style={{ marginTop: "15px" }}
                      onClick={handleRegisterClick}
                    >
                      GET STARTED
                    </button>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={snackBar}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      message="Please Enter Email and Password"
                      action={message}
                    />
                  </div>
                  <div className="main-container">
                    <div className="line"></div>
                    <span className="signin">OR CONTINUE WITH GOOGLE</span>
                    <div className="line"></div>
                  </div>
                  <div className="button-container">
                    <button
                      className="register-button"
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={signInWithGoogle}
                    >
                      <div className="google-button">
                        <img
                          src={hover ? grayGoogle : Google}
                          alt="Google"
                          height={"16px"}
                        ></img>
                        <span style={{ marginTop: "2px" }}>GOOGLE</span>
                      </div>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div className="wrapper">
      <MetaTags>
        <title>Sign In or Sign Up - Doro by Razroze</title>
        <meta name="description" content="Sign in or sign up with Doro by Razroze to access mental health questionnaires, track your progress, and engage in early self-awareness for better well-being." />
        <link rel="canonical" href="https://doro.razroze.ca/get-started" />
      </MetaTags>
      <div className="input-section" style={{ marginTop: "0px" }}>
        <label htmlFor="email">
          <div style={{marginLeft: "3%", marginTop: "30%"}}>
          Email
          </div>
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password" style={{ marginTop: "10px"}}>
          <div style={{marginLeft: "3%"}}>
          Password
          </div>
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {loading ? (
          <div
            className="progress-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress
              sx={{ color: "#FFFFFF" }}
              className="progress"
            />
          </div>
        ) : (
          <>
            <div className="main-container"> 
              <span className="signin" onClick={() => setShowResetpassPopup(true)} style={{ cursor: 'pointer' }}>Forgot password?</span>
              {showResetpassPopup && (
                <ForgotPassPopup
                    onClose={() => setShowResetpassPopup(false)}
                />
              )}
            </div>
            <div className="button-container" style={{ marginTop: "15px" }}>
              <button
                className="register-login-button"
                style={{ marginTop: "15px" }}
                onClick={handleRegisterClick}
              >
                Get Started
              </button>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackBar}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Please Enter Email and Password"
                action={message}
              />
            </div>
            <div className="main-container">
              <span className="signin">OR CONTINUE WITH GOOGLE</span>
            </div>
            <div className="button-container">
              <button
                className="register-google-button"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={signInWithGoogle}
              >
                <div className="google-button">
                  <img
                    src={hover ? grayGoogle : Google}
                    alt="Google"
                    height={"16px"}
                  ></img>
                  <span style={{ marginTop: "2px" }}>GOOGLE</span>
                </div>
              </button>
            </div>
          </>
        )}
      </div>
      <div className="image-container">
        <div className="heading" style={{paddingBottom: "12%"}}>Join the Journey</div>
        <div className="image"></div>
        <div className="paragraph" style={{paddingBottom: "12%"}}>Discover a supportive space for self-awareness and early intervention. Sign up to engage with Doro, access reliable questionnaries, and track your mental state over time.</div>
      </div>
      <Footer />
    </div>
  );
}

export default StartSection;
