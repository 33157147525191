import React, { useState } from "react";
import { Stack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import axiosInstance from "../../utils/axiosConfig";
import '../../styles/FeedbackPopup.css';
import close from '../../assets/close.svg';
import { useSelector } from "react-redux";
import { selectAuthToken } from "../../store/slices/authSlice";
import { useEffect } from "react";

function FeedbackPopup({ onClose, sessionID }) {
    const [feedback, setFeedback] = useState("");
    const [like, setLike] = useState(null);
    const token = useSelector(selectAuthToken);

    // Close the modal when the Escape key is pressed
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape' || event.key === 'Esc') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);


    const handleFeedbackSubmit = async () => {
        if (like === null) {
            return;
        };

        try {
            const response = await axiosInstance.post(
                `sessions/${sessionID.current}/feedback/`,
                {
                    comment: feedback,
                    liked: like,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                }
            );
        } catch (error) {
            console.error("Error in API call:", error);
        }
        onClose();
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="terms-popup" onClick={handleOverlayClick}>
            <div className="terms-content" style={{ padding: "35px 45px 35px 45px", margin: "0px 10px 0px 10px" }}>
                <Stack>
                    <button className="close-feedback-button" onClick={onClose}>
                        <img src={close} alt="Close" />
                    </button>
                </Stack>
                <p style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "10px", color: "#484F59", textAlign: "center"}}>
                    How was your session experience?
                </p>

                <div className="feedback-buttons">
                    <button
                        className={`feedback-button ${like === true ? 'selected like' : ''}`}
                        onClick={() => setLike(true)}
                    >
                        <ThumbUpIcon />
                    </button>
                    <button
                        className={`feedback-button ${like === false ? 'selected dislike' : ''}`}
                        onClick={() => setLike(false)}
                    >
                        <ThumbDownIcon />
                    </button>
                </div>

                <textarea
                    className="feedback-textarea"
                    placeholder="Leave your feedback for Doro here... (optional)"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    rows="4"
                />

                <button
                    className={`feedback-button`}
                    onClick={handleFeedbackSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
}

export default FeedbackPopup;
