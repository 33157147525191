/* Main module for handling WebSocket connection and messages in the Dorochat component. */
import { useEffect, useRef, useCallback, useState } from 'react';
import { backendURI, wsProtocol } from "../utils/axiosConfig";

// Define action types as constants to avoid typos
const ActionTypes = {
    STREAM: 'STREAM',
    NO_FURTHER_ACTION: 'NO_FURTHER_ACTION',
    // Add new action types here
};

export default function useWebSocket(token, isChatStarted, setMessages, setInputDisabled) {
    const websocket = useRef(null);
    const sessionEndedIntentionally = useRef(false);
    const sessionID = useRef(null);
    const sessionDuration = useRef(null);
    const totalSeconds = useRef(null);
    const [progress, setProgress] = useState(0);
    const [timer, setTimer] = useState(0);
    const errorTimeoutRef = useRef(null);
    const [isConnectionError, setConnectionError] = useState(false);

    // Helper function to manage connection error state with delay
    const setConnectionErrorWithDelay = (isError) => {
        if (isError) {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            errorTimeoutRef.current = setTimeout(() => {
                setConnectionError(true);
            }, 5000);
        } else {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            setConnectionError(false);
        }
    };

    // Function to send messages via WebSocket
    const sendMessage = useCallback((message, type = "text") => {
        if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
            console.error("WebSocket not connected. Cannot send message.");
            return;
        }

        websocket.current.send(JSON.stringify({ "m_type": type, "content": message }));

        // Update messages state
        if (message !== "" && type === "text") {
            setMessages((messages) => ([
                ...messages,
                { id: Date.now(), text: message, role: "user" },
                {
                    id: "typing-indicator",
                    text: "",
                    role: "assistant",
                    class: "typing-indicator",
                },
            ]));
        } else if (message !== "" && type === "audio") {
            setMessages((messages) => ([
                ...messages,
                {
                    id: "typing-indicator",
                    text: "",
                    role: "assistant",
                    class: "typing-indicator",
                },
            ]));
        }
    }, [setMessages]);

    // Handler functions for different action types
    const handleStreamAction = useCallback((response) => {
        const { role, action } = response;
        const { content } = action;

        if (typeof content !== 'string') {
            console.error('Invalid content in STREAM action:', action);
            return;
        }

        setMessages((messages) => {
            if (messages.length === 0 || messages[messages.length - 1].role !== role) {
                return [
                    ...messages,
                    { id: Date.now(), text: content, role: role },
                    // If the role is "user", add a typing indicator for the assistant
                    ...(role === "user" ? [{
                        id: "typing-indicator",
                        text: "",
                        role: "assistant",
                        class: "typing-indicator"
                    }] : [])
                ];
            } else {
                // Append content to the last message
                return messages.map((message, index) => {
                    if (index === messages.length - 1) {
                        return { ...message, text: message.text + content };
                    }
                    return message;
                });
            }
        });
    }, [setMessages]);

    const handleNoFurtherAction = useCallback(() => {
        // Enable input if the assistant has responded
        setInputDisabled(false);
    }, [setMessages]);

    // Dispatcher mapping action types to their handlers
    const actionHandlers = useRef({
        [ActionTypes.STREAM]: handleStreamAction,
        [ActionTypes.NO_FURTHER_ACTION]: handleNoFurtherAction,
        // Add new action handlers here
    }).current;

    // Function to receive and handle messages from the WebSocket
    const receiveMessage = useCallback((event) => {
        const response = JSON.parse(event.data);

        // Remove typing indicators
        setMessages((messages) => messages.filter((message) => message.id !== "typing-indicator"));

        // Handle session initialization
        if (!sessionID.current) {
            sessionID.current = response.session_id;
            sessionDuration.current = response.session_duration;
            totalSeconds.current = sessionDuration.current * 60;
            sendMessage("", "text");
            setInputDisabled(true);
            setMessages(() => [{
                id: "typing-indicator",
                text: "",
                role: "assistant",
                class: "typing-indicator"
            }]);
            return;
        }

        const { action } = response;

        // Validate action and action_type
        if (!action || typeof action.action_type !== 'string') {
            console.error('Invalid action or missing action_type:', action);
            return;
        }

        // Dispatch to the appropriate handler
        const handler = actionHandlers[action.action_type];
        if (handler) {
            handler(response);
        } else {
            console.error('Unknown action_type:', action.action_type);
        }
    }, [actionHandlers, sendMessage, setInputDisabled, setMessages]);

    // Effect to manage WebSocket connection lifecycle
    useEffect(() => {
        if (!isChatStarted || sessionEndedIntentionally.current) return;

        if (websocket.current && [WebSocket.OPEN, WebSocket.CONNECTING].includes(websocket.current.readyState)) {
            return;
        }

        if (!token) {
            console.error("No token available for authorization.");
            setConnectionErrorWithDelay(true);
            return;
        }

        console.log("Connecting to WebSocket...", websocket.current);
        websocket.current = new WebSocket(`${wsProtocol}://${backendURI}/chat/ws?token=${token}`);

        websocket.current.onopen = () => {
            sessionID.current = null;
            sessionEndedIntentionally.current = false;
            setConnectionErrorWithDelay(false);
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket Error: ", error);
            setConnectionErrorWithDelay(true);
        };

        websocket.current.onclose = (event) => {
            console.log("WebSocket closed with event: ", event);
            if (!sessionEndedIntentionally.current) {
                setConnectionErrorWithDelay(true);
            }
        };

        websocket.current.onmessage = receiveMessage;

        const closeWebSocket = (reason = "User left the session.") => {
            if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
                sessionEndedIntentionally.current = true;
                websocket.current.close(1000, reason);
            }
        };

        window.addEventListener("beforeunload", closeWebSocket);
        window.addEventListener("unload", closeWebSocket);

        return () => {
            window.removeEventListener("beforeunload", closeWebSocket);
            window.removeEventListener("unload", closeWebSocket);
            closeWebSocket("Session unmounted.");
        };

    }, [token, isChatStarted, receiveMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to manage session timer and progress
    useEffect(() => {
        if (!isChatStarted) return;

        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                const newTimer = prevTimer + 1;
                if (newTimer >= totalSeconds.current) {
                    clearInterval(intervalId);
                    setInputDisabled(true);
                    if (websocket.current) {
                        sessionEndedIntentionally.current = true;
                        websocket.current.close(1000, "Session ended.");
                    }
                    return totalSeconds.current;
                }
                return newTimer;
            });
        }, 1000); // Update every second

        return () => clearInterval(intervalId);
    }, [isChatStarted, setInputDisabled, totalSeconds]);

    // Update progress whenever timer changes
    useEffect(() => {
        setProgress((timer / totalSeconds.current) * 100);
    }, [timer, totalSeconds]);

    return { sendMessage, timer, progress, isConnectionError, sessionID };
}
