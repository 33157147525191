import { useContext, useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { DoroChatContext } from '../../context/DoroChatContext';
import ReactMarkdown from 'react-markdown';
import '../../styles/testimonials.css';
import Quote from './Quote';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { LayoutContext } from '../../context/LayoutContext';

const DoroChatContent = () => {
  const {isChatStarted, themeMode} = useContext(LayoutContext);
  const {chatContainerRef, messages, showQuote} = useContext(DoroChatContext);
  const lastMessageRef = useRef(null);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [sortedMessages, setSortedMessages] = useState([]);
  const [otherProps, setOtherProps] = useState({});
  const [chatFontSize, setChatFontSize] = useState('16px');
  const {windowSize} = useWindowSizeListener();
  const [removeQuote, setRemoveQuote] = useState(false);

  const handleScroll = () => {
    const chatSection = chatContainerRef.current;
    const isAtBottom = chatSection.scrollHeight - chatSection.scrollTop <= chatSection.clientHeight + 30;
    setIsUserAtBottom(isAtBottom);
  };

  useEffect(() => {
    if (lastMessageRef.current && isUserAtBottom) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sortedMessages, isUserAtBottom]);

  useEffect(() => {
    setSortedMessages(messages.sort((a, b) => a.id - b.id));
  }, [messages]);

  useEffect(() => {
    if (windowSize.deviceType === 'large') {
      setOtherProps({
        width: '80%',
      });
    } else {
      setChatFontSize('14px');
      setOtherProps({
        width: '90%',
      });
      if (windowSize.deviceType === 'mobile') {
        setChatFontSize('14px');
      }
    }
  }, [windowSize, isChatStarted]);

  useEffect(() => {
    if (!showQuote) {
      const timer = setTimeout(() => {
        setRemoveQuote(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [showQuote]);

  return (
    <Box
      component={'div'}
      className='chat-section'
      onScroll={handleScroll}
      ref={chatContainerRef}
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        flexGrow: 1,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxSizing: 'border-box',
        marginTop: '5%',
        zIndex: 10,
        scrollBehavior: 'smooth',
      }}>
      <Box
        sx={{
          ...otherProps,
          gap: '1%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '90%',
        }}>
        {!removeQuote && <Quote />}
        {sortedMessages?.map((message, index) => {
          const isTypingIndicator = message.id === 'typing-indicator';
          let messageClass = `chat-bubble ${message.role} ${themeMode}`;
          return (
            <div
              style={{
                fontSize: chatFontSize,
              }}
              key={message.id}
              className={messageClass}
              ref={index === messages.length - 1 ? lastMessageRef : null}>
              {isTypingIndicator?
                <div class={`typing-indicator ${themeMode}`}>
                  <span class="text">Doro is thinking </span><span class="dots">...</span>
                </div> :
                <ReactMarkdown>{message.text}</ReactMarkdown>}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default DoroChatContent;